import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {getRoles} from '../../../auth/selectors/auth.selector';
import {AppPermissions} from '../../../user/enum/app-permissions.enum';
import {firstValueFrom} from "rxjs";

@Pipe({
  name: 'hasRole',
})
export class HasRolePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  async transform(requestedPermissions: string[] | string, excludeAdmin = false) {
    const loggedRoles = await firstValueFrom(this.store.select(getRoles));

    if (!requestedPermissions
      || requestedPermissions.length == 0
      || loggedRoles?.includes(AppPermissions.SUPERADMIN)) {
      return true;
    }

    return this.userHasAtLeastOnePermission(loggedRoles, requestedPermissions);
  }

  userHasAtLeastOnePermission(userDistinctPermissions: string[], requestedPermissions: string[] | string): boolean {
    return !!(userDistinctPermissions && userDistinctPermissions.some(x => requestedPermissions.includes(x)));
  }
}
