import {Injectable} from '@angular/core';
import {Directory, Filesystem} from "@capacitor/filesystem";
import {MediaMatcher} from "@angular/cdk/layout";
import {PlatformEnum} from "../../enums/platform.enum";
import {environment} from "../../../../environments/environment";
import {Capacitor} from "@capacitor/core";
import {HttpClient} from "@angular/common/http";
import {DeviceService} from "../device/device.service";
import {App} from "@capacitor/app";
import {MobileStoreDialogComponent} from "../../components/mobile-store-dialog/mobile-store-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FileOpener} from "@capacitor-community/file-opener";
import {Share} from "@capacitor/share";
import AndroidFilePicker, {
  AndroidPickedFile,
  AndroidPickFileOptions,
  AndroidPickMediaOptions,
  AndroidPickResult
} from "../../interfaces/android-file-picker-plugin.interface";
import {CookieService} from "ngx-cookie";
import {CookiesEnum} from "../../enums/cookies.enum";
import {lastValueFrom} from "rxjs";
import {Photo} from "@capacitor/camera";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  platform: PlatformEnum;

  constructor(
    private mediaMatcher: MediaMatcher,
    private http: HttpClient,
    private deviceService: DeviceService,
    private dialog: MatDialog,
    private readonly cookieService: CookieService
  ) {
    this.platform = PlatformEnum[Capacitor.getPlatform()];
  }

  async getMobileMinVersion() {
    const platform = this.deviceService.getPlatformEnum();
    const $data = this.http.get<{minVersion: string}>(`${environment.BASE_PATH}/mobile/min-version/${platform}`);
    const res = await lastValueFrom($data);

    return res?.minVersion;
  }

  async isMobileVersionUpdated() {
    if(!this.deviceService.isMobileDevice()) {
      return true;
    }
    const appInfo = await App.getInfo();
    return appInfo.version >= await this.getMobileMinVersion();
  }

  async downloadAndOpenFileExc(data: string | Blob, fileName: string) {
    let uriToDelete;
    if (data instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = async () => {
        uriToDelete = await this.downloadAndOpenFile(String(reader.result), fileName);
      }
    } else {
      uriToDelete = await this.downloadAndOpenFile(data, fileName);
    }
    // if (uriToDelete) {
    //   await Filesystem.deleteFile({path: uriToDelete});
    // }
  }

  async downloadAndOpenFile(base64: string, fileName: string) {
    let uriToDelete: string;
    try {
      const result = await Filesystem.writeFile({
        path: fileName,
        data: <string>base64,
        directory: Directory.Data,
        recursive: true
      });
      await this.openOrShareFile(result.uri, fileName);
      uriToDelete = result.uri;
      console.log('Wrote file', result.uri);
    } catch (e) {
      console.error('Unable to write file', e);
    }
    return uriToDelete;
  }

  async openOrShareFile(uri: string, filename: string) {
    try {
      await FileOpener.open({
        filePath: uri,
        openWithDefault: true,
      });
      console.log("Filed opened", uri);
    } catch (e) {
      console.error('Unable to open file, try sharing...');
      await this.shareFile(uri, filename);
    }
  }

  async shareFile(uri: string, filename: string) {
    try {
      await Share.share({
        title: 'Scarica file',
        text: 'Salva il file ' + filename,
        url: uri,
        dialogTitle: 'Scarica file',
      });
      console.log('File shared', uri);
    } catch (e) {
      console.error('Unable to share', e);
    }
  }

  isMobileDevice() {
    return this.mediaMatcher.matchMedia('(max-width: 800px)').matches;
  }

  getAndroidVersion() {
    const userAgent = navigator.userAgent.toLowerCase();
    const version = userAgent.match(/android\s([0-9\.]*)/i);
    return version[1];
  }

  getMapboxTokenBasedOnDevice() {
    return this.platform == PlatformEnum.ios && environment.production ?
      environment.MAPBOX_KEY_IOS : environment.MAPBOX_KEY;
  }

  private async androidUriToFile(res: AndroidPickResult) {
    let files: File[] = [];
    const resultFiles = JSON.parse(res.result) as AndroidPickedFile[];

    for(const file of resultFiles) {
      const fileName = file.fileName;
      const contentType = file.mimeType;

      const readRes = await Filesystem.readFile({ path: file.uri });
      let byteCharacters;
      if (typeof readRes.data === "string") { // NB: solo per nativo
        byteCharacters = atob(readRes.data);
      }
      const blob = this.toBlob(byteCharacters, contentType);

      files.push(new File([blob], fileName, { type: contentType }));
    }
    return files;
  }

  toBlob(rawData: string, contentType: string): Blob {
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    return new Blob([arr], {type: contentType});
  }

  async androidCameraPhotoToFile(photo: Photo): Promise<File> {
    const contentType = photo.format;
    const fileName = new Date().getTime().toString();
    const rawData = atob(photo.base64String);

    const blob = this.toBlob(rawData, contentType)
    return new File([blob], fileName, { type: contentType })
  }

  async androidPickMedia(options: AndroidPickMediaOptions) {
    const res = await AndroidFilePicker.pickMedia(options);
    return await this.androidUriToFile(res);
  }

  async androidPickFile(options: AndroidPickFileOptions) {
    const res = await AndroidFilePicker.pickFile(options);
    return await this.androidUriToFile(res);
  }

  async openUpdateDialogIfNotUpdated() {
    if(!(await this.isMobileVersionUpdated())) {
      this.dialog.open(MobileStoreDialogComponent, { data: {
          dialogTitle: "Aggiorna Check",
          description: "La versione che stai utilizzando di Check va aggiornata:" +
            "<br/>per farlo scarica quella piu' recente dallo store ufficiale cliccando il bottone 'Vai allo store'",
          storeBtnLabel: "Vai allo store",
          disableClose: true
        }
      });
    }
  }

  async openAppDownloadDialogIfNeeded() {
    const hasToShowDownloadDialog = !this.cookieService.get(CookiesEnum.DownloadAppDialogShown);

    if(hasToShowDownloadDialog
      && this.platform != PlatformEnum.ios
      && this.platform != PlatformEnum.android) {
      this.dialog.open(MobileStoreDialogComponent, { data: {
          dialogTitle: "Scarica l'app di Check",
          description: "Scarica l'applicazione di Check dallo store ufficiale cliccando il bottone 'Vai allo store'." +
            "<br/>Provala anche da mobile",
          storeBtnLabel: "Vai allo store",
          disableClose: false
        }
      });

      this.cookieService.put(CookiesEnum.DownloadAppDialogShown, 'true');
    }
  }

  openSettings(androidSettings?: AndroidSettings, iosSettings?: IOSSettings) {
    return NativeSettings.open({
      optionAndroid: androidSettings ||AndroidSettings.ApplicationDetails,
      optionIOS: iosSettings || IOSSettings.App,
    });
  }
}
